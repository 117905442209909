import React from 'react';
import {HashRouter as Router,Switch,Route} from 'react-router-dom';
import ChildView from './components/ChildView';
import Dashboard from './view/Dashboard';
import './App.css';
import Login from './components/Login';
import ChangePass from './components/ChangePass';

function App() {
  return (
    <Router>
      <div>
        <Switch>
        <Route exact path='/:token' component={Login}/>
          <Route exact path='/child/:np/:fc/:us' component={ChildView}/>
          <Route exact path='/dashboard/:sid/:grp/:grn' component={Dashboard}/>
          <Route exact path='/change' component={ChangePass}/>
        </Switch>
      </div>
      </Router>
  );
}

export default App;
