import React,{useState,useEffect, useRef} from 'react';
import axios from 'axios';
import './main.css';
import { Container,Row,Col,Card,Button,Navbar } from 'react-bootstrap';
import Loader from './Loader';
import Logo from '../logo_dash.png';
import Logout from 'react-icons/lib/io/log-out';
import {MdAnnouncement} from 'react-icons/lib/md';

function ChildView(props) {
    const { match } = props;
    let {np,fc,us} = match.params;
    let [childView, setChildView] = useState([]);
    let ref = useRef();
    // console.log(np,us,"props");
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        `http://116.202.231.219:8069/Weblogin/Login?Sid=${np}&Cont4=${fc}&Pswd=${us}`
      ).then(res => setChildView(res.data))
      .catch(function (error) {
        console.log(error);
      });
      console.log(childView,"Child")
    };
    fetchData();
  }, []);

  const downloadFile = () => {
		fetch('http://aksolutions.pk/PDFs/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
    },
  })
  .then((response) => response.blob())
  .then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Invoice.pdf`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    // link.parentNode.removeChild(link);
  });

	}
     
  const handleLogout = () =>{
    localStorage.clear();
    props.history.push('/'); 
  }

  const handleChange = () =>{
    props.history.push('/change'); 
  }

  const handleClick = (sid,grp,grn) =>{
      props.history.push(`/dashboard/${sid}/${grp}/${grn}`); 
    }
    
      return (
        <div className="childPanel">
          <Navbar bg="light">
            <Navbar.Brand><img style={{width:"20%"}} src={Logo}/> ESMIS APP</Navbar.Brand>
            <div className="rightSide" >
              <h5><button className="btnBack" onClick={() => {handleChange() }}>Change Password</button> <span className="Logout" onClick={() => {handleLogout() }}>LOGOUT <Logout/></span></h5>
            </div>
          </Navbar>
           <Container>
            <Row>
            {childView.length > 0 ? (childView.map(post =>
              <Col md={4} key={post.GRN}>
              <Card  className="childCard">
              <Card.Img variant="top" src={`data:image/jpeg;base64,${post.LOGO}`} className="schoolImage" />
                <Card.Body className="childBody">
                  <Card.Img variant="top" src={`data:image/jpeg;base64,${post.Spic}`} className="childImage" />
                  <Card.Title className="childName">{post.StName}</Card.Title>
                  <div className="boxRed box-shadow">
                    <h5 className='childHeading'><MdAnnouncement/> School Alert</h5>
                      <div className='redBox'>
                        <p className="newsFeed">{post.NewsFeed}</p>
                      </div>
                  </div>
                  <div className="boxGreen box-shadow">
                    <h5 className='childHeading'><MdAnnouncement/> Notice For Student</h5>
                      <div className='greenBox'>
                        <p className="newsFeed">{post.WebMsg}</p>
                      </div>
                  </div>
                  <Button type="button" className="btnDashboard" onClick={(e) => handleClick(post.Sid,post.Grp,post.GRN)}>Dashboard</Button>
                  <Button type="button" onClick={(e)=> downloadFile()} >Download</Button>
                </Card.Body>
              </Card>
             </Col>
             )):(<Loader/>) }
          </Row>
          </Container>
          </div>
      );
    }
    
export default ChildView;
