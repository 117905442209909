import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from 'axios';
import Loader from '../components/Loader';

class PieChart extends React.Component {
      
  constructor(props) {
    super(props);

        this.state = {
          sid:props.sid,
          grn:props.grn,
          grp:props.grp,
          loading:true,
          options: {
            labels: ['Present', 'Absent'],
            fill: {
              colors: ['#28a745', '#dc3545']
            },
            colors: ['#28a745', '#dc3545'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
          series: [8, 1]
        }
      }
    
  

  componentDidMount(){
    axios.get(`http://192.99.173.212:8069/weblogin/graph_attd?sid=${this.state.sid}&grp=${this.state.grp}&grn=${this.state.grn}`)
    .then(res => {
      const dataOptions = res.data.m_Item1[0].Categories;
      const dataSeries = res.data.m_Item2[0].data;
      console.log(dataOptions,"dataOp");
      console.log(dataSeries,"dataSer");
      this.setState({series:dataSeries,loading:false});
      this.setState({
        options: {
          labels: dataOptions,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        }
      ,loading:false})
    
    })
  }

  render() {
    return (
      <div className="chart">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="pie"
              width="280"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default PieChart;