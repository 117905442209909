import React,{useState} from 'react';
import axios from 'axios';
import './main.css';
import Logo from '../logo.png';
import Loader from '../components/Loader';
import { Container,Row,Col,Card,Button,Form,Alert } from 'react-bootstrap';

function ChangePass(props) {
  const [newPassword,setNewPassword]=useState('');
  const [confirmPassword,setConfirmPassword]=useState('');
  const [errorMessage,setErrorMessage]=useState('');
  const [okMessage,setOkMessage]=useState('');
  const [loading,setLoading]=useState(false);
  let cellno = localStorage.getItem("C");
  let sid = localStorage.getItem("N");
  // console.log(passs,'pass');
  const onSubmit = e =>{
    e.preventDefault();
    if(newPassword != confirmPassword ){
      setErrorMessage("New and confirm password not match");
    }
    else{
      setLoading(true);
      axios({
        method: 'post',
        url: 'http://116.202.231.219:8069/WebLogin/Password',
        data: {
          Sid: sid,
          CellNo:cellno,
          NewPswd:newPassword
        }
      }).then(res=> setOkMessage(res.data))
      .catch(err=> setErrorMessage('Password change not successfully'));
      }
      props.history.push('/');
  }

  return (
    <div className="loginPanel">
      <Container>
        <Row>
          <Col>
          <Card className="cardLogin">
          <img src={Logo} className="Logo"/>
          {loading == true ? (
          <Card.Body>
          <h3 className="loginHeading">Login Panel</h3>
          <Loader/> 
          </Card.Body> 
          ):(
            <Card.Body>
              <h3 className="loginHeading">Change Password</h3>
            <Form onSubmit={onSubmit}>
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <Form.Control type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} placeholder="New Password" />
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} placeholder="Confirm Password" />
                {okMessage ? <div className="success">{okMessage}</div>:''}
                {errorMessage ? <div className="error">{errorMessage}</div>:''}
              </Form.Group>
              <Button type="submit" className="btnLogin">Change Password</Button>
            </Form>
            </Card.Body>
          )}
          </Card>
          </Col>
          </Row>
      </Container>
      </div>
  );
}

export default ChangePass;
