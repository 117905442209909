import React,{useState} from 'react';
import axios from 'axios';
import './main.css';
import Logo from '../logo.png';
import Loader from '../components/Loader';
import { Container,Row,Col,Card,Button,Form } from 'react-bootstrap';

function Login(props) {
  const { match } = props;
  let {token} = match.params;
    const [contactNumber,setContactNumber]=useState('');
    const [fatherNumber,setFatherNumber]=useState('');
    const [password,setPassword]=useState('');
    const [errorMessage,setErrorMessage]=useState('');
    const [okMessage,setOkMessage]=useState('');
    const [loading,setLoading]=useState(false);

    const onSubmit = e =>{
      e.preventDefault();
      console.log('Login');
      if(contactNumber == "" && password == "" && fatherNumber == ""){
        setErrorMessage("Please fill all required fields");
      }
      else{
        setLoading(true);
        axios.get(`http://116.202.231.219:8069/Weblogin/Login?Sid=${contactNumber}&Cont4=${fatherNumber}&Pswd=${password}`)
        .then(function (response) {
          // console.log(response,"res");
          let sid = response.data[0].Sid;
          let cellno = response.data[0].CellNo;
          let grp = response.data[0].Grp;
          let _token = response.data[0].MobToken; 
          if(response.data[0].Active === 'Y'){
            setErrorMessage('');
            setOkMessage('Login Successfully');
            if(_token != token){
              //Push Notification
              axios({
                  method: 'post',
                  url: 'http://192.99.173.212:8069/gcm/Token',
                  data: {
                    Sid: sid,
                    GRP: grp,
                    CellNo:cellno,
                    Token:token
                  }
                })
                .then(res=> {
                  console.log(res.data,'Send token');
                  })
                .catch(err => console.log(err,"not send"));
              //Push Notification
                }
            console.log(response.data[0].CellNo,"datares");
            localStorage.setItem('t',token);
            localStorage.setItem("N",contactNumber);
            localStorage.setItem("U",password);
            localStorage.setItem("F",fatherNumber);
            localStorage.setItem('C',response.data[0].CellNo);
            props.history.push(`/child/${contactNumber}/${fatherNumber}/${password}`); 
          }
          else{
            setLoading(false);
            setErrorMessage('Contact to school administration');
          }
        })
        .catch(function (error) {
          if(error){
            setLoading(false);
            setOkMessage('');
            setErrorMessage('Username or Password is Invalid');
          }});
      }
    }
  return (
    <div className="loginPanel">
      <Container>
        <Row>
          <Col>
          <Card className="cardLogin">
          <img src={Logo} className="Logo"/>
          {loading == true ? (
          <Card.Body>
          <h3 className="loginHeading">Login Panel</h3>
          <Loader/> 
          </Card.Body> 
          ):(
            <Card.Body>
              <h3 className="loginHeading">Login Panel</h3>
            <Form onSubmit={onSubmit}>
              <Form.Group>
                {/* <Form.Label>Contact Number</Form.Label> */}
                <Form.Control type="text" value={contactNumber} onChange={e => setContactNumber(e.target.value)} placeholder="Your School ID" />
                <br/>
                <Form.Control type="text" value={fatherNumber} onChange={e => setFatherNumber(e.target.value)} placeholder="Your Cell Number" />
                <br/>
                <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
                {okMessage ? <div className="success">{okMessage}</div>:''}
                {errorMessage ? <div className="error">{errorMessage}</div>:''}
              </Form.Group>
              <Button type="submit" className="btnLogin">Login</Button>
            </Form>
            </Card.Body>
          )}
          </Card>
          </Col>
          </Row>
      </Container>
      </div>
  );
}

export default Login;
