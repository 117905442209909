import React, { Component } from "react";
import {Button,Card} from 'react-bootstrap';
import Chart from "react-apexcharts";
import MdRefresh from 'react-icons/lib/md/refresh';
import axios from 'axios';
class App extends Component {
  constructor(props) {
    // //console.log(props,"props");
    super(props);

    this.state = {
      sid:props.sid,
      grn:props.grn,
      grp:props.grp,
      options: {
        
      },
      series: []
    };
  }
  
  componentDidMount(){
    axios.get(`http://192.99.173.212:8069/weblogin/graph_fees?sid=${this.state.sid}&grp=${this.state.grp}&grn=${this.state.grn}`)
    .then(res => {
      const dat = res.data.m_Item2;
      const dat1 = res.data.m_Item1;
      // console.log(dat1[0].Categories,"data");
      this.setState({series:dat});
      this.setState({
        options:{
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '25%',
              endingShape: 'rounded'	
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: dat1[0].Categories,
          },
          yaxis: {
            title: {
              text: 'PKR (thousands)'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "PKR " + val + " thousands"
              }
            }
          }
        }
      })
    })
     }

  handleClick = () => {
      axios.get(`http://192.99.173.212:8069/weblogin/graph_fees?sid=${this.state.sid}&grp=${this.state.grp}&grn=${this.state.grn}`)
    .then(res => {
      const dat = res.data.m_Item2;
      const dat1 = res.data.m_Item1;
      // console.log(dat1[0].Categories,"data");
      this.setState({series:dat});
      this.setState({
        options:{
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '25%',
              endingShape: 'rounded'	
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: dat1[0].Categories,
          },
          yaxis: {
            title: {
              text: 'PKR (thousands)'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "PKR " + val + " thousands"
              }
            }
          }
        }
      })
    })
    };
  render() {
    return (
      <Card className="box-shadow">
            <h3 className="headingInfo">Fees Graph<span style={{float:'right'}}>
            {this.state.series.length > 0? (<Button disabled title="Refresh" onClick={(e) => this.handleClick()} style={{padding:'3px 8px'}} type="button">
              <MdRefresh style={{fontSize:'20px',color:'white'}}/></Button>):(<Button title="Refresh" onClick={(e) => this.handleClick()} style={{padding:'3px 8px'}} type="button">
              <MdRefresh style={{fontSize:'20px',color:'white'}}/></Button>)}</span>
            </h3>
            {this.state.series.length > 0 ? (
              <Card.Body>
              <div className="chart">
                <div className="row">
                  <div className="mixed-chart">
                    <Chart
                      options={this.state.options}
                      series={this.state.series}
                      type="bar"
                      width="500"
                    />
                  </div>
                </div>
              </div>
              </Card.Body>
            ):(<Card.Body>
              <div>
                <p style={{textAlign:'center',fontSize:'16px'}}>Click refresh button</p>
              </div>
              </Card.Body>)}
            
        </Card>
      );
  }
}

export default App;