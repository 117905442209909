import React,{useState,useEffect,Fragment} from 'react';
import axios from 'axios';
import './dashboard.css';
import Logo from '../logo_dash.png';
import { Container,Row,Col,Card,Table,Navbar,ProgressBar,Button} from 'react-bootstrap';
import {MdAnnouncement} from 'react-icons/lib/md';
import {FaWhatsapp,FaBuildingO,FaPhone,FaEnvelopeO,FaMapMarker,FaLocationArrow,FaExternalLink,FaFacebook,FaTags} from 'react-icons/lib/fa';
import MdRefresh from 'react-icons/lib/md/refresh';
import Logout from 'react-icons/lib/io/log-out'
import PieChart from '../charts/PieChart';
import BarChart from '../charts/BarChart';
import Loader from '../components/Loader';
import { Form } from 'react-bootstrap';

function Dashboard(props) {
  const { match } = props;
  let {sid,grp,grn} = match.params;
  let [studentData, setStudentData] = useState([]);
  let [comment, setComment] = useState('');
  let [sendComment, setSendComment] = useState('');
  let [required, setRequired] = useState('');

  const handleClick = () =>{
    localStorage.clear();
    props.history.push('/'); 
  }
  let conNumber = localStorage.getItem("N");
  let fNumber = localStorage.getItem("F");
  let passs = localStorage.getItem("U");
  const handleClick1 = () =>{
    props.history.push(`/child/${conNumber}/${fNumber}/${passs}`); 
  }

  useEffect(() => {
    const studentFunData = async () => {
      const result = await axios.get(
        `http://192.99.173.212:8069/weblogin/dashboard?sid=${sid}&grp=${grp}&grn=${grn}`
      ).then(response => setStudentData(response.data));
    };
    studentFunData();
  }, []);
  
  function formatDate(date) {
    var monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun", "Jul",
      "Aug", "Sep", "Oct",
      "Nov", "Dec"
    ];
  
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
  
    return day + '-' + monthNames[monthIndex] + '-' + year;
  }
  
  const handleStuClick = async () => {
    const result = await axios.get(
      `http://192.99.173.212:8069/weblogin/dashboard?sid=${sid}&grp=${grp}&grn=${grn}`
    ).then(res => console.log(res,"dashboard"));
  };
  
  const onSubmit = e =>{
    e.preventDefault();
    if(comment === "" ){
      // setErrorMessage("Please fill all required fields");
      }
    else{
      axios({
        method: 'post',
        url: 'http://192.99.173.212:8069/WebLogin/Comments',
        data: {
          Sid: sid,
          Grp: grp,
          Grn:grn,
          Comment:comment
        }
      })
      .then(res=> {
        setSendComment(res.data);
        console.log(setComment(),'bcom');
        setComment('');
        console.log(setComment(),'acom');
      })
      .catch(err => console.log(err));
      }
      console.log(sendComment,"comment");
  }
  return (
    <div className="dashboard">
      <Navbar bg="light">
        <Navbar.Brand><img style={{width:"20%"}} src={Logo}/> ESMIS APP</Navbar.Brand>
        <div className="rightSide" >
          <h5><button className="btnBack" onClick={() => {handleClick1() }}>Go Back</button> <span onClick={() => {handleClick() }}>LOGOUT <Logout/></span></h5>
        </div>
      </Navbar>
      <Container>
         <Row>
          <Col md={12}>
          {studentData.map(post1 =>
            <div className="schoolImage">
              <img className="box-shadow" src={`data:image/jpeg;base64,${post1.LOGO}`}></img>
            </div>
          )}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="tickerArea">
            <div className="boxRed box-shadow">
            <h5 className='headingDetail'><MdAnnouncement/> School Alerts</h5>
            {studentData.length > 0 ? (studentData.map(post2 =>
              <div className='redBox'>
                <p className="newsFeed">{post2.NewsFeed}</p>
              </div>
            )):(<div className='redBox'>
            <Loader/>
          </div>)}
            </div>
          </Col>
        </Row> 
        <Row>
        <Col md={4}>
          <Card className="studentData box-shadow">
              <h3 className="headingInfo">Student Information<span style={{float:'right'}}>
              {studentData.length > 0? (<Button disabled title="Refresh" onClick={(e) => handleClick()} style={{padding:'3px 8px'}} type="button">
                <MdRefresh style={{fontSize:'20px',color:'white'}}/></Button>):(<Button title="Refresh" onClick={(e) => handleStuClick()} style={{padding:'3px 8px'}} type="button">
                <MdRefresh style={{fontSize:'20px',color:'white'}}/></Button>)}
                </span>
              </h3>
              {studentData.length > 0 ? (studentData.map(post =>(
              <>
              <Card.Img className="stuImage" src={`data:image/jpeg;base64,${post.Spic}`} />
              <Card.Body>
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <td>Student Name</td>
                      <td className="bold">{post.Name}</td>
                    </tr>
                    <tr>
                      <td>Father Name</td>
                      <td className="bold">{post.Fname}</td>
                    </tr>
                    <tr>
                      <td>Class Title/Sec</td>
                      <td className="bold">{post.Class}</td>
                    </tr>
                    <tr>
                      <td>GRN</td>
                      <td className="bold">{post.GRN}</td>
                    </tr>
                    <tr>
                      <td>Shift</td>
                      <td className="bold">{post.Shft}</td>
                    </tr>
                    <tr>
                      <td>Session</td>
                      <td className="bold">20{post.Yer}</td>
                    </tr>
                    <tr>
                      <td>Campus</td>
                      <td className="bold">Campus</td>
                    </tr>
                    <tr>
                      <td>Addmission Date</td>
                      <td className="bold">{formatDate(new Date(post.Trdate))}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
              </>))):(
                <Card.Body>
                    <Table striped bordered hover>
                      <tr>
                          <td colspan='2' style={{textAlign:'center'}}>Click refresh button</td>
                      </tr>
                    </Table>
                  </Card.Body>
                  )}
            </Card>
        </Col> 
        <Col md={3}>
        <div>
        <div className="boxBlue box-shadow">
           <h5 className='headingDetail'>Fees Dues</h5>
           {studentData.length > 0 ?(studentData.map(feesPost =>
             <div className='blueBox'>
               <h3 className='title'>Rs. {feesPost.FBal}</h3>
             </div>
             )):(<div className='blueBox'><p style={{textAlign:'center',color:'white'}}>Click refresh button</p></div>)}
           </div>
          
           <div className="boxGreen box-shadow">
             <h5 className="headingDetail">Academic Status</h5>
              {studentData.length > 0 ?(studentData.map(statusPost =>
             <div className="greenBox">
                <h3 className="title">{statusPost.Result}</h3>
             </div>
             )):(<div className='greenBox'><p style={{textAlign:'center',color:'white'}}>Click refresh button</p></div>)}
           </div>
           
           <div className="boxRed box-shadow">
             <h5 className="headingDetail">Attendence Status</h5>
            {studentData.length > 0 ? (studentData.map(attPost =>
             <div className="redBox" key={attPost.Progress}>
                 <ProgressBar animated className="box-shadow" now={attPost.Progress} label={`${attPost.Progress}%`} />
                 <p className="text">{`Present = ${attPost.Present}`}</p>
                 <p className="text">{`Absent = ${attPost.Absent}`}</p>
                 <p className="text">{`Leave = ${attPost.Leave}`}</p>
             </div>
           )):(<div className="redBox"><p style={{textAlign:'center',color:'white'}}>Click refresh button</p></div>)}       
      </div>
 
          <BarChart sid={sid} grp={grp} grn={grn}/>
        </div>
        </Col>
        <Col md={5}>
        
          <div className="msg box-shadow">
            <h5 className="msgTitle">Parents Guide</h5>
            <div className="msgBox">
            {studentData.length > 0 ?(studentData.map(post =>
              <p>{post.MsgText}</p>
            )):(<Loader/>)}
            </div>
          </div>
        
        
          <div className="diary box-shadow">
            <h5 className="diaryTitle">Daily Diary</h5>
            <div className="diaryBox">
            {studentData.length > 0 ?(studentData.map(post =>
              <p>{post.DiaryText}</p>
              )):(<Loader/>)}
            </div>
          </div>
        
        
        
          <div className="news box-shadow">
            <h5 className="newsTitle">Home Work Intsructions</h5>
            <div className="newsBox">
              {studentData.length > 0 ? (studentData.map(post =>
                <p>{post.HWText}</p>
                )):(<Loader/>)}
            </div>
          </div>
        
        </Col>
      </Row>
      <Row>
        <Col md={6} className="feesDataDetail">
        <Card className="box-shadow">
          <h3 className="headingInfo">Fees Detail Ledger</h3>
          <Card.Body>
          {studentData.map(post =>(
          <Table striped bordered hover responsive className="FeesDetailTable">
            <thead>
              <tr>
                <th>Date</th>
                <th>Vno</th>
                <th>Class</th>
                <th>Post</th>
                <th>Receive</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                  <td className="bold">{post.F1}</td>
                  <td className="bold">{post.F2}</td>
                  <td className="bold">{post.F3}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F4}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F5}</td>
                </tr>
                <tr>
                  <td className="bold">{post.F11}</td>
                  <td className="bold">{post.F12}</td>
                  <td className="bold">{post.F13}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F14}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F15}</td>
                </tr>
                <tr>
                  <td className="bold">{post.F21}</td>
                  <td className="bold">{post.F22}</td>
                  <td className="bold">{post.F23}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F24}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F25}</td>
                </tr>
                <tr>
                  <td className="bold">{post.F31}</td>
                  <td className="bold">{post.F32}</td>
                  <td className="bold">{post.F33}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F34}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F35}</td>
                </tr>
                <tr>
                  <td className="bold">{post.F41}</td>
                  <td className="bold">{post.F42}</td>
                  <td className="bold">{post.F43}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F44}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F45}</td>
                </tr>
                <tr>
                  <td className="bold">{post.F51}</td>
                  <td className="bold">{post.F52}</td>
                  <td className="bold">{post.F53}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F54}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F55}</td>
                </tr>
                <tr>
                  <td className="bold">{post.F61}</td>
                  <td className="bold">{post.F62}</td>
                  <td className="bold">{post.F63}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F64}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F65}</td>
                </tr>
                <tr>
                  <td className="bold">{post.F71}</td>
                  <td className="bold">{post.F72}</td>
                  <td className="bold">{post.F73}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F74}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F75}</td>
                </tr>
                <tr>
                  <td className="bold">{post.F81}</td>
                  <td className="bold">{post.F82}</td>
                  <td className="bold">{post.F83}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F84}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F85}</td>
                </tr>
                <tr>
                  <td className="bold">{post.F91}</td>
                  <td className="bold">{post.F92}</td>
                  <td className="bold">{post.F93}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F94}</td>
                  <td className="bold" style={{textAlign:'right'}}>{post.F95}</td>
                </tr>
             </tbody>
              </Table>
          ))}
          </Card.Body>
        </Card>
        </Col>
        <Col md={6} className="feesDataDetail">
        {studentData.length > 0 ? (<PieChart sid={sid} grp={grp} grn={grn}/>):(<p>Click Refresh</p>)}
          
        </Col>
      </Row>
      <Row>
        <Col md={12} className="feesDataDetail">
        <Card className="box-shadow">
          <h3 className="headingInfo">Result History (Summarized)</h3>
          <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
            {studentData.map(termPost => (
              <>
              <tr>
                <th>Year / ___Class</th>
                <th>{termPost.Term1}</th>
                <th>{termPost.Term2}</th>
                <th>{termPost.Term3}</th>
                <th>{termPost.Term4}</th>
                <th>{termPost.Term5}</th>
                <th>{termPost.Term6}</th>
              </tr>
              </>
            ))}
              <tr>
                <th></th>
                <th>Per % - Grade</th>
                <th>Per % - Grade</th>
                <th>Per % - Grade</th>
                <th>Per % - Grade</th>
                <th>Per % - Grade</th>
                <th>Per % - Grade</th>
              </tr>
            </thead>
            
            <tbody>
            {studentData.length > 0 ? (studentData.map(post => (
              <>
              <tr>
                  <td className="bold">{post.R1}</td>
                  <td className="bold">{post.R2}</td>
                  <td className="bold">{post.R3}</td>
                  <td className="bold">{post.R4}</td>
                  <td className="bold">{post.R5}</td>
                  <td className="bold">{post.R6}</td>
                  <td className="bold">{post.R7}</td>
                </tr>
                <tr>
                <td className="bold">{post.R11}</td>
                <td className="bold">{post.R12}</td>
                <td className="bold">{post.R13}</td>
                <td className="bold">{post.R14}</td>
                <td className="bold">{post.R15}</td>
                <td className="bold">{post.R16}</td>
                <td className="bold">{post.R17}</td>
              </tr>
              <tr>
                <td className="bold">{post.R21}</td>
                <td className="bold">{post.R22}</td>
                <td className="bold">{post.R23}</td>
                <td className="bold">{post.R24}</td>
                <td className="bold">{post.R25}</td>
                <td className="bold">{post.R26}</td>
                <td className="bold">{post.R27}</td>
              </tr>
              <tr>
                <td className="bold">{post.R31}</td>
                <td className="bold">{post.R32}</td>
                <td className="bold">{post.R33}</td>
                <td className="bold">{post.R34}</td>
                <td className="bold">{post.R35}</td>
                <td className="bold">{post.R36}</td>
                <td className="bold">{post.R37}</td>
              </tr>
              <tr>
                <td className="bold">{post.R41}</td>
                <td className="bold">{post.R42}</td>
                <td className="bold">{post.R43}</td>
                <td className="bold">{post.R44}</td>
                <td className="bold">{post.R45}</td>
                <td className="bold">{post.R46}</td>
                <td className="bold">{post.R47}</td>
              </tr>
              <tr>
                <td className="bold">{post.R51}</td>
                <td className="bold">{post.R52}</td>
                <td className="bold">{post.R53}</td>
                <td className="bold">{post.R54}</td>
                <td className="bold">{post.R55}</td>
                <td className="bold">{post.R56}</td>
                <td className="bold">{post.R57}</td>
              </tr>
              <tr>
                <td className="bold">{post.R61}</td>
                <td className="bold">{post.R62}</td>
                <td className="bold">{post.R63}</td>
                <td className="bold">{post.R64}</td>
                <td className="bold">{post.R65}</td>
                <td className="bold">{post.R66}</td>
                <td className="bold">{post.R67}</td>
              </tr>
              <tr>
                <td className="bold">{post.R71}</td>
                <td className="bold">{post.R72}</td>
                <td className="bold">{post.R73}</td>
                <td className="bold">{post.R74}</td>
                <td className="bold">{post.R75}</td>
                <td className="bold">{post.R76}</td>
                <td className="bold">{post.R77}</td>
              </tr>
              <tr>
                <td className="bold">{post.R81}</td>
                <td className="bold">{post.R82}</td>
                <td className="bold">{post.R83}</td>
                <td className="bold">{post.R84}</td>
                <td className="bold">{post.R85}</td>
                <td className="bold">{post.R86}</td>
                <td className="bold">{post.R87}</td>
              </tr>
              <tr>
                <td className="bold">{post.R91}</td>
                <td className="bold">{post.R92}</td>
                <td className="bold">{post.R93}</td>
                <td className="bold">{post.R94}</td>
                <td className="bold">{post.R95}</td>
                <td className="bold">{post.R96}</td>
                <td className="bold">{post.R97}</td>
              </tr>
              </>))):(<tr>
                <td colspan='26' style={{textAlign:'center'}} className="bold">Click refresh button</td>
              </tr>)} 
             </tbody>
              </Table>
          </Card.Body>
        </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="feesDataDetail">
        <Card className="box-shadow">
          <h3 className="headingInfo">Feedback / Comment</h3>
          <Card.Body>
          {studentData.map((schCmnt) =>{
            return(
              <>
              {schCmnt.CActive === 'Y' ? (
                <>
                <Form onSubmit={onSubmit}>
                <Form.Group>
                  <Form.Label>Comment Box</Form.Label>
                  <Form.Control as="textarea" rows="3" required onChange={e => setComment(e.target.value)}/>
                </Form.Group>
                <Button type="submit" variant="danger">Submit</Button>
              </Form>
              {sendComment ? <div className="success">{sendComment}</div>:''}
              </>
              ):(
                <Form>
                <Form.Group>
                  <Form.Label>Comment Box</Form.Label>
                  <Form.Control disabled placeholder='Your Comments disabled' as="textarea" rows="3" />
                </Form.Group>
                <Button disabled variant="danger">Submit</Button>
              </Form>
              )}
            </>
            )
          })}  
          
          </Card.Body>
          </Card>
        </Col>
      </Row> 
       <Row>
        <Col md={12} className="feesDataDetail">
        <Card className="box-shadow">
          <h3 className="headingInfo">School Info</h3>
          <Card.Body>
          <Row>
          {studentData.map(schPost =>(
            <Col md={6}>
            <p className="schText"><span><FaBuildingO className="schIcons"/></span> {schPost.SchName}</p>
            <p className="schText"><span><FaTags className="schIcons"/></span> {schPost.SchTAG}</p>
            <p className="schText"><span><FaPhone className="schIcons"/></span> {schPost.SchPh}</p>
            <p className="schText"><span><FaWhatsapp className="schIcons"/></span> {schPost.SchWA}</p>
            <p className="schText"><span><FaEnvelopeO className="schIcons"/></span> {schPost.SchEm}</p>
            </Col>
            ))}
            {studentData.map(schPost =>(
            <Col md={6}>
            <p className="schText"><span><FaExternalLink className="schIcons"/></span> <a target='_blank' href={schPost.SchWEB}> {schPost.SchWEB}</a></p>
            <p className="schText"><span><FaFacebook className="schIcons"/></span> <a href={schPost.SchFB} target='_blank'> {schPost.SchFB}</a></p>
            <p className="schText"><span><FaLocationArrow className="schIcons"/></span> {schPost.SchADR}</p>
            <p className="schText"><span><FaMapMarker className="schIcons"/></span> <a href={schPost.SchMAP} target='_blank'>Our Location</a></p>
            </Col>
            ))}
          </Row>
          </Card.Body>
          </Card>
        </Col>
      </Row> 
      </Container>
      <div className="footer">
      {studentData.map(ftPost =>(
        <p>{ftPost.Ak_Footer} <a href={ftPost.Ak_Link}>{ftPost.Ak_Dealer}</a> Cell No: {ftPost.Ak_CelNo} Email: {ftPost.Ak_Email} </p>
      ))}
        </div>
    </div>
    
    );
}

export default Dashboard;
