import React, {Fragment } from "react";
import {Button,Spinner} from 'react-bootstrap';
import axios from 'axios';
import './main.css';

function Loader() {
  return (
            <div className="spinner">
                <Spinner
                    as="span"
                    animation="border"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    />
            </div> 
        );
        }

export default Loader;



